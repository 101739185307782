import styled from "styled-components";
import {
    TABLET_MAX_WIDTH,
    MOBILE_MAX_WIDTH,
    MOBILE_MIN_WIDTH,
} from "@constants";
import { Link } from "gatsby";
import { ReactSVG } from "react-svg";

export const HeaderWithStyles = styled.header`
    width: 100vw;
    position: fixed;
    top: 0;
    background: var(--dark-purple);
    z-index: 20;
    white-space: pre-wrap;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        background-color: transparent;
        position: relative;
    }
`;

export const HeaderContainer = styled.div`
    margin: 0 auto;
    height: 6.5rem;
    padding: 0 4rem 0 3rem;
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        height: 5.5rem;
        margin-bottom: -5.5rem;
        padding: 0 2.5rem;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        height: 3.5rem;
        margin-bottom: -3.5rem;
        padding: 0 1.5rem 0 1.6875rem;
    }
`;

export const LogoLinkContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const LogoLink = styled(Link)`
    &:focus-visible {
        outline: none;
    }
`;

export const LogoIcon = styled(ReactSVG)`
    padding-right: 2rem;
    margin: 0;
    border-right: 0.0625rem solid rgba(196, 181, 128, 0.3);

    & svg {
        height: 2.75rem;
        width: 1.063rem;
    }

    &:focus-visible {
        outline: none;
    }

    @media only screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        border-right: none;
        display: flex;
        align-items: center;

        & svg {
            width: 0.8125rem;
            height: 2rem;
        }
    }
`;

export const TextContainer = styled.span`
    display: flex;
    align-items: center;
    font-weight: 200;
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin-left: 2rem;
    color: var(--main-font-color);

    & > p > strong {
        color: white;
    }
`;
export const ActionsContainer = styled.div`
    display: flex;
    flex-direction: row;
`;
