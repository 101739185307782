import React, { memo, useEffect, useState } from "react";
import logo from "@images/impressit-logo.svg";
import useWindowDimensions from "@hooks/useWindowDimensions";
import { LAYOUT_PADDING_TOP, HEADER_HEIGHT } from "@constants";
import {
    HeaderWithStyles,
    HeaderContainer,
    LogoLinkContainer,
    LogoLink,
    LogoIcon,
    TextContainer,
    ActionsContainer,
} from "./styles/Header.styles";
import NavButtons from "./components/NavButtons";
import BurgerMenu from "./components/BurgerMenu";
import { IMPRESSIT_LOGO_ALTERNATIVE_TEXT, IMPRESSIT_LOGO_ARIA_LABEL } from "./constants";

const isWindowAvailable = typeof window !== "undefined";

const Header = ({
    headerTitle = (
        <p>
            Welcome to <strong>impressit</strong>
        </p>
    ),
    showLinks,
    handleClick,
    openMobileFilters,
    setOpenMobileFilters,
    path,
    setShowLinks,
}) => {
    const { height, isDesktop, isMobile } = useWindowDimensions();
    const [currentOpacity, setCurrentOpacity] = useState(0);
    const [didMount, setDidMount] = useState(false);
    useEffect(() => {
        if (isDesktop !== undefined && isMobile !== undefined) {
            setDidMount(true);
        }
        return () => setDidMount(false);
    }, [isDesktop, isMobile]);

    useEffect(() => {
        if (isDesktop === undefined || !isDesktop) {
            return;
        }

        const scrollHandler = () => {
            const animationHeight = LAYOUT_PADDING_TOP - HEADER_HEIGHT;
            const opacity =
                Math.round((window.scrollY / animationHeight) * 100) / 100;

            setCurrentOpacity(() => (opacity >= 1 ? 1 : opacity));
        };

        document.addEventListener("scroll", scrollHandler);

        return () => document.removeEventListener("scroll", scrollHandler);
    }, [height, isDesktop]);

    if (isWindowAvailable && didMount) {
        /*  eslint-disable */
    if (location.pathname === "/blog" || location.pathname.includes("/blog")) {
      headerTitle = (
        <p>
          Learn with <strong>impressit</strong>
        </p>
      )
    }
  }

  return (
    didMount && (
      <HeaderWithStyles
        style={{
          background: `rgba(43, 9, 52, ${currentOpacity})`,
        }}
        className="header"
      >
        <HeaderContainer>
          <LogoLinkContainer>
            <LogoLink to="/">
              <LogoIcon 
                src={logo} 
                aria-label={IMPRESSIT_LOGO_ARIA_LABEL}
                alt={IMPRESSIT_LOGO_ALTERNATIVE_TEXT} 
              />
            </LogoLink>
            {!isMobile && <TextContainer>{headerTitle}</TextContainer>}
          </LogoLinkContainer>
          <ActionsContainer>
            {isDesktop && <NavButtons />}
            <BurgerMenu
              setShowLinks={setShowLinks}
              path={path}
              handleClick={handleClick}
              showLinks={showLinks}
              openMobileFilters={openMobileFilters}
              setOpenMobileFilters={setOpenMobileFilters}
            />
          </ActionsContainer>
        </HeaderContainer>
      </HeaderWithStyles>
    )
  )
}

export default memo(Header)
