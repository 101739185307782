import React from "react";
import "./Footer.scss";
import LinksContainer from "./components/LinksContainer/LinksContainer";
import FooterInfo from "./components/FooterInfo/FooterInfo";
import { FooterSocialMediaInfo } from "./components";

const Footer = () => (
    <footer className="footer">
        <FooterInfo />
        <div className="footer__info-container">
            <LinksContainer />
        </div>
        <FooterSocialMediaInfo />
    </footer>
);

export default Footer;
