import styled from "styled-components"
import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants"

export const HeaderButtons = styled.div`
  display: flex;
  align-items: center;

  & button:first-child {
    margin-right: 1.5rem;
  }

  & button:nth-child(2) {
    margin-right: 2rem;
  }

  @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
    margin: 2.5rem 0;
    & button {
      width: 9.25rem !important;
      animation-name: appearance;
      animation-duration: 1s;
      transition: ${props => (props.isClosing ? "1s" : "none")};
      opacity: ${props => (props.isClosing ? "0 !important" : "auto")};
      @keyframes appearance {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 0.99;
        }
      }
    }

    & button:first-child {
      margin-right: 1rem;
    }

    & button:nth-child(2) {
      margin-right: 0;
    }
  }

  @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
    margin: 1.75rem 0 0;
    flex-direction: column;
    padding: 0 1.5rem;

    & a {
      width: 100%;
    }

    & button {
      width: 100% !important;
    }

    & button:first-child {
      margin: 0 0 1rem 0;
    }
  }
`
