import styled from "styled-components";
import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants";

export const LinkContainer = styled.div`
    flex-grow: 100;
    margin-top: 7.315rem;
    box-sizing: border-box;
    height: 100vh;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        margin-top: 0;
        width: 100vw;
        height: auto;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        margin-top: 1.5rem;
    }
`;

export const FirstLinksSection = styled.div`
    border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.2);
    padding: 0.7rem 0 2.125rem 8.3rem;
    letter-spacing: 0.07rem;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        display: flex;
        margin-top: 0;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        width: 100%;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        flex-direction: column;
        border-bottom: none;
    }
`;

export const SecondLinksSection = styled.div`
    border-right: 0.0625rem solid rgba(255, 255, 255, 0.2);
    width: 25%;
    padding: 2.2rem 2.5rem 0 2.2rem;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        width: 50%;
        padding-right: 0;
        box-sizing: border-box;
        padding-left: 6rem;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        padding: 0;
        margin: 0 1.5rem 0 1.6875rem;
        border-right: none;
        border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.2);
        width: calc(100vw - 3.1875rem);
    }
`;

export const ThirdLinksSection = styled.div`
    margin-left: 2.5rem;
    width: 24.8%;
    padding-top: 2.5rem;
    border-right: 0.0625rem solid rgba(255, 255, 255, 0.2);

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        border-right: none;
        width: 50%;
        box-sizing: border-box;
        margin-left: 0;
        padding-left: 2rem;
        padding-bottom: 1.5rem;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        margin-top: 1.5rem;
        padding: 0;
        margin: 1.5rem 1.5rem 1.5rem 1.6875rem;
        border-right: none;
        border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.2);
        width: calc(100vw - 3.1875rem);
    }
`;

export const SecondaryLinksContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-left: 6.125rem;
    border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.2);
    letter-spacing: 0.05rem;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.2);
        min-height: 23.125rem;
        padding-left: 0;
        width: 100%;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        flex-direction: column;
        padding-left: 0;
        min-height: 37rem;
        border-bottom: none;
    }
`;

export const SectionTitle = styled.p`
    color: rgba(255, 255, 255, 0.6);
    font-weight: 600;
    font-size: 0.8125rem;
    line-height: 1.25rem;
    margin: 0;
`;

export const FourthLinksSection = styled.div`
    margin-left: 2.5rem;
    padding-top: 2.5rem;
    width: 30%;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        border-right: none;
        width: 50%;
        box-sizing: border-box;
        margin-left: 0;
        padding-left: 2rem;
        padding-bottom: 1.5rem;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        padding: 0;
        margin: 1.5rem 1.5rem 0 1.6875rem;
        border-right: none;
        border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.2);
        width: calc(100vw - 3.1875rem);
    }
`;

export const SubServicesLinks = styled.div`
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    p {
        margin: 0;
        padding-right: 1.4rem;
        border-right: 0.0625rem solid #ffffff33;

        &:last-child {
            margin-left: 1.5rem;
        }
    }
`;

export const MainLinksContainer = styled.div`
    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        width: 50%;
        border-right: 0.0625rem solid #ffffff33;
        padding-top: 2.5rem;
        box-sizing: border-box;
        padding-left: 6rem;

        h2 {
            width: 12.5rem;
            &:last-child {
                width: auto;
            }
        }
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        padding: 0;
        margin: 0 1.5rem 0 1.6875rem;
        border-right: none;
        border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.2);
        width: calc(100vw - 3.1875rem);

        h2 {
            width: 100%;
        }
    }
`;
