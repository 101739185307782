import React, { useCallback, useMemo, useState, memo, useEffect, useLayoutEffect } from "react";
import useCookie from "react-use-cookie";
import useDelayedRender from "@hooks/useDelayedRender"
import PropTypes from "prop-types";
import Header from "@common/Header/Header";
import Footer from "@common/Footer/Footer";
import ArrowUp from "@common/ArrowUp/ArrowUp";
import ContactSection from "@common/ContactSection/ContactSection";
import {
    CONTACT_US_PAGE_PATH,
    NOT_FOUND_PAGE_PATH,
    GDPR_PAGE_PATH,
    PRIVACY_POLICY_PAGE_PATH,
    DEDICATED_TEAMS_PAGE_PATH,
    THANK_YOU_PATH,
    OFFICE_PATH,
    CAREERS_PATH,
    BLOG_PAGE_PATH,
} from "@constants";
import classNames from "classnames";
import { Waypoint } from "react-waypoint";
import { Context } from "../../../context";
import "./Layout.scss";
import ConsentBanner from "../ConsentBanner"
import ConsentContextProvider from "../../../contexts/ConsentContext";

const Layout = ({ children, path, isNoPadding }) => {
    const [showLinks, setShowLinks] = useState(false);
    const [userToken, setUserToken] = useCookie("impressit");
    const [headerTitle, setHeaderTitle] = useState();
    const [openMobileFilters, setOpenMobileFilters] = useState(false);
    const [layoutClassName, setLayoutClassName] = useState("layout");
    const [layoutContainerClassName, setLayoutContainerClassName] = useState("layout__container");
    const [isContentLoaded, setIsContentLoaded] = useState(false);
    const { isDelayed } = useDelayedRender(1000);

    useLayoutEffect(() => {
        document.documentElement.style.background = "#2B0934";
    }, []);

    useEffect(() => {
        setIsContentLoaded(true);
    }, []);

    useEffect(() => {
        setLayoutClassName(
            classNames("layout", {
                "layout--show-menu": showLinks === true,
                "layout--small-retreat": path === OFFICE_PATH,
                "layout--hidden": isNoPadding,
            })
        );
        setLayoutContainerClassName(
            classNames("layout__container", {
                "layout-container--small-retreat": path === OFFICE_PATH,
                "layout--blog": path === BLOG_PAGE_PATH,
                "layout--full-screen": isNoPadding,
            })
        );
    }, [showLinks, path]);

    const layoutBottomContainer = classNames("layout__bottom-container", {
        "layout__bottom-container-open-filters": !!openMobileFilters,
    });

    const handleClick = useCallback(
        e => {
            e.preventDefault();

            if (showLinks) {
                setTimeout(() => {
                    setShowLinks(false);
                }, 1000);
            } else {
                setShowLinks(true);
            }
        },
        [setShowLinks, showLinks]
    );

    const showContactSection = useMemo(
        () =>
            path !== NOT_FOUND_PAGE_PATH &&
            path !== CONTACT_US_PAGE_PATH &&
            path !== PRIVACY_POLICY_PAGE_PATH &&
            path !== GDPR_PAGE_PATH &&
            path !== THANK_YOU_PATH &&
            path?.includes(BLOG_PAGE_PATH) &&
            !path?.includes(CAREERS_PATH) &&
            !/\d/.test(path),
        [
            path,
            NOT_FOUND_PAGE_PATH,
            CONTACT_US_PAGE_PATH,
            PRIVACY_POLICY_PAGE_PATH,
            GDPR_PAGE_PATH,
            THANK_YOU_PATH,
            CAREERS_PATH,
        ]
    );

    const isDedicatedTeamsPage = useMemo(
        () => path === DEDICATED_TEAMS_PAGE_PATH,
        [path, DEDICATED_TEAMS_PAGE_PATH]
    );

    const isHomePage = path === "/";

    useEffect(() => {
        const body = document.getElementsByTagName("body")[0];
        if (showLinks) {
            body.style.overflowY = "hidden";
        }
        if (!showLinks) {
            body.style.overflowY = "scroll";
        }
    }, [showLinks]);

    useEffect(() => {
        window.gtag = function() { window.dataLayer?.push(arguments);};
        if (window.localStorage.getItem("impressit") === null) {
          window.gtag("consent", "default", {
            functionality_storage: "denied",
            security_storage: "denied",
            ad_storage: "denied",
            ad_user_data: "denied",
            ad_personalization: "denied",
            analytics_storage: "denied",
            personalization_storage: "denied",
            wait_for_update: 500,
          })
        } else {
          window.gtag("consent", "default", JSON.parse(localStorage.getItem("impressit")))
        }
    }, [])

    return (
        isContentLoaded && (
        <ConsentContextProvider>
            <div className={layoutClassName}>
                <Header
                    headerTitle={headerTitle}
                    handleClick={handleClick}
                    openMobileFilters={openMobileFilters}
                    setOpenMobileFilters={setOpenMobileFilters}
                    path={path}
                    setShowLinks={setShowLinks}
                    showLinks={showLinks}
                />
                <main className={layoutContainerClassName}>
                    <Context.Provider
                        value={{
                            setHeaderTitle,
                            openMobileFilters,
                            setOpenMobileFilters,
                        }}
                    >
                        {children}
                    </Context.Provider>
                    <ArrowUp />
                </main>
                {isDelayed && ( 
                <div className={layoutBottomContainer}>
                    <div className="layout__bottom">
                        {showContactSection ? (
                            <div>
                                <ContactSection
                                    buttonText={
                                        isDedicatedTeamsPage
                                            ? "Get in touch"
                                            : "Contact us page"
                                    }
                                />
                                {isHomePage && (
                                    <Waypoint
                                        onEnter={() =>
                                            setHeaderTitle(
                                                <p>
                                                    Contact us and get
                                                    <strong> impressed</strong>
                                                </p>
                                            )
                                        }
                                    />
                                )}
                            </div>
                        ) : null}
                        <Footer />
                    </div>
                </div>
                )}
                <ConsentBanner />
            </div>
            </ConsentContextProvider>
        )
    );
};
Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default memo(Layout);
