import React, { memo, useMemo, useState, useCallback, useEffect } from "react";
import useWindowDimensions from "@hooks/useWindowDimensions";
import burgerIcon from "@images/ic-burger.svg";
import closeIcon from "@images/ic-cancel.svg";
import doneIcon from "@images/ic-done.svg";
import { graphql, useStaticQuery } from "gatsby";
import LinksContainer from "./LinksContainer";
import NavButtons from "./NavButtons";
import {
  BurgerMenuContainer,
  Icon,
  IconContainer,
  IconText,
  LinksWrapper,
  LinksContainerStyles,
  BackdropBlur,
} from "../styles/BurgerMenu.styles";

const BurgerMenu = ({
  showLinks,
  handleClick,
  openMobileFilters,
  setOpenMobileFilters,
  path,
  setShowLinks,
}) => {
  const [isClosing, setIsClosing] = useState(true);
  const [isBurgerProcessed, setIsBurgerProcessed] = useState(true);

  const { isDesktop, isTablet, isMobile } = useWindowDimensions();

  const menuIcon = useMemo(
    () => (showLinks && !isClosing ? closeIcon : burgerIcon),
    [showLinks, burgerIcon, isClosing]
  );
  const caseStudies = useStaticQuery(graphql`
    query CaseStudiesPageQuery {
      allStrapiCaseStudies {
        edges {
          node {
            wideImage {
              url
            }
            metaUrl
            logo {
              url
            }
            title
            weMade
          }
        }
      }
    }
  `);
  const [caseStudyData, setCaseStudyData] = useState(null);

  useEffect(() => {
    const caseStudiesEdges = caseStudies.allStrapiCaseStudies.edges.filter(
      el => el.node.wideImage
    );

    const randomIndex = Math.round(
      Math.random() * (caseStudiesEdges.length - 1)
    );

    const { wideImage, logo, title, weMade, metaUrl } =
      caseStudiesEdges[randomIndex].node;

    setCaseStudyData({
      image: wideImage.url,
      logo: logo.url,
      title,
      weMade,
      metaUrl,
    });
  }, [caseStudies]);

  const preventScrolling = useCallback(e => {
    e.preventDefault();
  }, []);

  useEffect(
    () => () => {
      document.removeEventListener("wheel", preventScrolling, {
        passive: false,
      });
    },
    [showLinks === true]
  );

  const generateLinksContainer = useMemo(() => {
    const mainContent = (
      <>
        <LinksContainer setShowLinks={setShowLinks} path={path} />
        {isMobile && <NavButtons />}
      </>
    );
    if (!isDesktop) {
      return (
        <LinksWrapper
          style={{
            maxHeight: `${
              isMobile ? window.innerHeight : window.innerHeight - 104
            }px`,
          }}
        >
          {mainContent}
        </LinksWrapper>
      );
    }
    return mainContent;
  }, [isDesktop, isMobile, window.innerHeight]);

  const handleMenuToggle = useCallback(
    e => {
      if (!isBurgerProcessed) return;

      setIsBurgerProcessed(false);
      setTimeout(() => {
        setIsBurgerProcessed(true);
      }, 1000);

      if (showLinks) {
        document.removeEventListener("wheel", preventScrolling, {
          passive: false,
        });
        setIsClosing(true);
      } else {
        document.addEventListener("wheel", preventScrolling, {
          passive: false,
        });
        setIsClosing(false);
      }
      handleClick(e);
    },
    [
      isBurgerProcessed,
      setIsBurgerProcessed,
      showLinks === true,
      setIsClosing,
      handleClick,
    ]
  );

  const handleFilterToggle = useCallback(() => {
    setTimeout(() => {
      // will be run after the rest of the code
      setOpenMobileFilters(false);
    }, 1);
  }, [setIsBurgerProcessed]);

  return (
    caseStudyData && (
      <>
        {showLinks && (
          <>
            {isTablet && <NavButtons isClosing={isClosing} />}
            <LinksContainerStyles isClosing={isClosing}>
              {generateLinksContainer}
            </LinksContainerStyles>
            <BackdropBlur />
          </>
        )}
        <BurgerMenuContainer>
          {!openMobileFilters ? (
            <IconContainer onClick={handleMenuToggle}>
              <Icon showLinks={showLinks} src={menuIcon} alt="burger icon" />
              <IconText>{showLinks && !isClosing ? "Close" : "Menu"}</IconText>
            </IconContainer>
          ) : (
            <IconContainer onClick={handleFilterToggle}>
              <Icon
                src={doneIcon}
                alt="done icon"
                className="burger-menu__filters-done-icon"
              />
            </IconContainer>
          )}
        </BurgerMenuContainer>
      </>
    )
  );
};

export default memo(BurgerMenu);
