import React from "react";
import useWindowDimensions from "@hooks/useWindowDimensions"
import { COMPANY_SOCIAL_MEDIAS } from "../../constants";
import {
  StyledFooterSocialInfoLink,
  StyledFooterSocialInfoLinkIcon,
} from "../../components/FooterSocialMediaInfo/styled";

const useFooterSocialMediaInfoConfiguration = () => {
  const { isMobile } = useWindowDimensions();
  const currentYear = new Date().getFullYear();

  const companySocialMedias = COMPANY_SOCIAL_MEDIAS.map(({ ariaLabel, link, icon }) => (
      <StyledFooterSocialInfoLink
        target="_blank"
        aria-label={ariaLabel}
        href={link}
        key={link}
        rel="noreferrer">
        <StyledFooterSocialInfoLinkIcon src={icon} />
      </StyledFooterSocialInfoLink>
    )
  )

  return { isMobile, companySocialMedias, currentYear };
}

export default useFooterSocialMediaInfoConfiguration;
