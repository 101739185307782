import React from "react";
import Button from "@common/Button/Button";
import {
    HEADER_SECONDARY_BUTTON,
    HEADER_MAIN_BUTTON,
    MAIN_BUTTON,
    SECONDARY_BUTTON,
} from "@constants";
import { GLOBAL_APPLICATION_EXTERNAL_LINKS, GLOBAL_APPLICATION_INTERNAL_LINKS } from "../../../../links";
import { HEADER_FIRST_BUTTON_TEXT, HEADER_SECOND_BUTTON_TEXT } from "../constants";
import { HeaderButtons } from "../styles/NavButtons.styles";

const NavButtons = ({ isClosing }) => {
    return (
        <HeaderButtons isClosing={isClosing}>
            {/* <Button
                text={HEADER_FIRST_BUTTON_TEXT}
                type={SECONDARY_BUTTON}
                size={HEADER_SECONDARY_BUTTON}
                href={GLOBAL_APPLICATION_EXTERNAL_LINKS.STARTUP_KIT}
            /> */}
            <Button
                text={HEADER_SECOND_BUTTON_TEXT}
                type={MAIN_BUTTON}
                size={HEADER_MAIN_BUTTON}
                href={GLOBAL_APPLICATION_INTERNAL_LINKS.CONTACT_US}
            />
        </HeaderButtons>
    );
};

export default NavButtons;
