import instagramIcon from "@images/ic-inst.svg";
import linkedinIcon from "@images/ic-linkedin.svg";
import youtubeIcon from "@images/ic-youtube.svg";
import { GLOBAL_APPLICATION_EXTERNAL_LINKS } from "../../../links";

export const COMPANY_SOCIAL_MEDIAS = [
    {
        ariaLabel: "Impressit Instagram",
        icon: instagramIcon,
        link: GLOBAL_APPLICATION_EXTERNAL_LINKS.INSTAGRAM
    },
    {
        ariaLabel: "Impressit Linkedin",
        icon: linkedinIcon,
        link: GLOBAL_APPLICATION_EXTERNAL_LINKS.LINKEDIN,
    },
    {
        ariaLabel: "Impressit Youtube",
        icon: youtubeIcon,
        link: GLOBAL_APPLICATION_EXTERNAL_LINKS.YOUTUBE,
    },
];

export const SOCIAL_MEDIA_MAX_WIDTH = "1050px";