import React from "react";
import { GLOBAL_APPLICATION_INTERNAL_LINKS } from "@links";
import { useFooterSocialMediaInfoConfiguration } from "../../hooks";
import {
  StyledFooterSocialMediasWrapper,
  StyledFooterSocialInfoCopyright,
  StyledFooterSocialInfoLinksContainer,
  StyledLink,
  StyledPrivacyPolicy,
  StyledGDPR,
  StyledPrivacyContainer
} from "./styled";

const FooterSocialMediaInfo = () => {
  const { isMobile, companySocialMedias, currentYear } = useFooterSocialMediaInfoConfiguration();
  return (
    <StyledFooterSocialMediasWrapper>
      <StyledFooterSocialInfoCopyright>
        <StyledPrivacyContainer>
          <StyledLink to={GLOBAL_APPLICATION_INTERNAL_LINKS.COOKIE_POLICY}>
            <StyledPrivacyPolicy>Cookies Policy</StyledPrivacyPolicy>
          </StyledLink>
          <StyledLink to={GLOBAL_APPLICATION_INTERNAL_LINKS.PRIVACY_POLICY}>
            <StyledPrivacyPolicy>Privacy policy</StyledPrivacyPolicy>
          </StyledLink>
          <StyledLink to={GLOBAL_APPLICATION_INTERNAL_LINKS.GDPR}>
            <StyledGDPR>GDPR</StyledGDPR>
          </StyledLink>
        </StyledPrivacyContainer>
        <span>
          Copyright © 2018-{currentYear} Impressit.
          {isMobile && <br />}&nbsp; All rights reserved.
        </span>
      </StyledFooterSocialInfoCopyright>
      <StyledFooterSocialInfoLinksContainer>
        {companySocialMedias}
      </StyledFooterSocialInfoLinksContainer>
    </StyledFooterSocialMediasWrapper>
  )
}

export default FooterSocialMediaInfo
