import React from "react";
import { ReactSVG } from "react-svg";
import "./FooterInfo.scss";
import logo from "@images/impressit-logo.svg";
import { StaticImage } from "gatsby-plugin-image";
import { GLOBAL_APPLICATION_EXTERNAL_LINKS } from "@links";

const FooterInfo = () => (
    <div className="footer-info">
        <div className="footer-info-container">
            <div className="footer-info-logo-container">
                <ReactSVG
                    src={logo}
                    className="footer-info-logo"
                    alt="impressit logo"
                />
                <h2>impressit</h2>
            </div>
            <div className="footer-info__proud-container">
                <a
                    target="_blank"
                    href={GLOBAL_APPLICATION_EXTERNAL_LINKS.CLUTCH_IMPRESSIT}
                    className="footer-info__proud-image-container"
                    rel="nofollow noreferrer"
                >
                    <StaticImage
                        src="../../../../../images/clutch-global-spring-logo.svg"
                        alt="clutch-global-spring"
                        className="footer-info__proud-image"
                    />
                </a>
                <a
                    target="_blank"
                    href={GLOBAL_APPLICATION_EXTERNAL_LINKS.GOODFIRMS_IMPRESSIT}
                    className="footer-info__proud-image-container"
                    rel="nofollow noreferrer"
                >
                    <StaticImage
                        src="../../../../../images/good-firms.svg"
                        alt="good-firms"
                        className="footer-info__proud-image"
                    />
                </a>
                <a
                    target="_blank"
                    href={GLOBAL_APPLICATION_EXTERNAL_LINKS.WADLINE_IMPRESSIT}
                    className="footer-info__proud-image-container"
                    rel="nofollow noreferrer"
                >
                    <StaticImage
                        src="../../../../../images/wadline-logo.svg"
                        alt="wadline"
                        className="footer-info__proud-image"
                    />
                </a>
                <a
                    target="_blank"
                    href={GLOBAL_APPLICATION_EXTERNAL_LINKS.UPCITY_IMPRESSIT}
                    className="footer-info__proud-image-container"
                    rel="nofollow noreferrer"
                >
                    <StaticImage
                        quality={100}
                        src="../../../../../images/upcity-logo.svg"
                        alt="upcity"
                        className="footer-info__proud-image"
                    />
                </a>
            </div>
        </div>
    </div>
);

export default FooterInfo;
