import styled from "styled-components";
import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants";
import { ReactSVG } from "react-svg";

export const BurgerMenuContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const IconContainer = styled.div`
    z-index: 5;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;

    & .burger-menu__filters-done-icon {
        border: 0.0625rem solid rgba(255, 255, 255, 0.3);
        width: 1.875rem;
        height: 1.875rem;

        svg {
            margin: 0.5rem 0.375rem;
        }
    }
`;

export const Icon = styled(ReactSVG)`
    cursor: pointer;
    height: 2.5rem;
    width: 2.5rem;
    margin-right: 0.25rem;

    @media only screen and (min-width: ${MOBILE_MIN_WIDTH}px) {
        border: ${props => (props.showLinks ? "none" : "auto")};

        svg {
            width: 2.5rem;
            height: 2.5rem;
            padding: 0;
        }
    }
`;

export const IconText = styled.span`
    font-size: 1.125rem;
    display: flex;
    align-items: center;
    color: #ffffff;
    opacity: 0.8;
    font-family: "Nunito Sans", sans-serif;
    height: 1.7rem;
    line-height: 1rem;
`;

export const LinksWrapper = styled.div`
    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        overflow-y: scroll;
        overflow-x: hidden;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    @media only screen and (min-width: ${MOBILE_MIN_WIDTH}px) and (max-width: ${TABLET_MAX_WIDTH}px) {
        display: flex;
        margin-top: 5.625rem;
        flex-direction: column;
        align-items: center;
    }
    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        box-sizing: border-box;
        margin-top: 3.5rem;
        overflow-x: hidden;
        overflow-y: scroll;
        padding-bottom: 3.5rem;
    }
`;

export const LinksContainerStyles = styled.div`
    z-index: -3;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: var(--dark-purple);
    border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.2);
    display: flex;
    animation-name: appearance;
    animation-duration: 1s;
    opacity: 0.99;
    transition: ${props => (props.isClosing ? "1s" : "none")};
    opacity: ${props => (props.isClosing ? "0 !important" : "auto")};

    @keyframes appearance {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0.99;
        }
    }

    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 0.0625rem;
        background: rgba(255, 255, 255, 0.2);
        top: 6.5rem;
        left: 0;
        right: 0;
    }

    & > p {
        margin: 0 0 0 -0.5rem;
    }

    @media only screen and (max-height: 850px) {
        overflow-x: hidden;
    }
    @media only screen and (max-width: 1350px) {
        flex-direction: column;
        height: 100vh;

        &::before {
            top: 5.5rem;
        }
    }
    @media only screen and (max-height: 52.5rem) {
        overflow-y: hidden;
        height: 100vh;
    }

    @media only screen and (max-width: 767px) {
        border-bottom: none;
        overflow: hidden;

        &::before {
            top: 3.5rem;
            margin: 0 1.4rem 0 1.6875rem;
            position: fixed;
            width: auto;
        }
    }
`;

export const BackdropBlur = styled.div`
    z-index: -5;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 50vh;
    animation-name: appearanceBackDropBlur;
    animation-duration: 0.5s;
    backdrop-filter: blur(0.4375rem);
    transition: ${props => (props.isClosing ? "0.5s" : "0s")};
    backdrop-filter: ${props => (props.isClosing ? "blur(0)" : "none")};

    @keyframes appearanceBackDropBlur {
        0% {
            backdrop-filter: blur(0);
        }
        100% {
            backdrop-filter: blur(0.4375rem);
        }
    }
`;
