import styled from "styled-components"
import { Link } from "gatsby"
import { ReactSVG } from "react-svg"
import { SOCIAL_MEDIA_MAX_WIDTH } from "../../constants";

export const StyledFooterSocialMediasWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: ${SOCIAL_MEDIA_MAX_WIDTH}) {
    flex-direction: column-reverse;
  }
`;

export const StyledFooterSocialInfoCopyright = styled.div`
  color: var(--main-font-color);
  font-size: 0.875rem;
  line-height: 1.5rem;
  display: grid;
  grid-template-columns: auto 2fr;
  gap: 24px;

  @media only screen and (max-width: ${SOCIAL_MEDIA_MAX_WIDTH}) {
    grid-template-columns: auto;
    text-align: center;
    gap: 16px;
  }
`;

export const StyledFooterSocialInfoLinksContainer = styled.div`
  display: flex;

  @media only screen and (max-width: ${SOCIAL_MEDIA_MAX_WIDTH}) {
    margin-bottom: 1.375rem;
  }
`;

export const StyledPrivacyContainer = styled.div`
  display: flex;
  gap: 16px;

  @media only screen and (max-width: ${SOCIAL_MEDIA_MAX_WIDTH}) {
    text-align: center;
    justify-content: center;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const StyledPrivacyPolicy = styled.span`
  color: var(--main-white);
  font-size: 0.875rem;
  line-height: 1.5rem;

  &:hover {
    color: var(--main-gold);
  }

`;

export const StyledGDPR = styled.span`
  color: var(--main-white);
  font-size: 0.875rem;
  line-height: 1.5rem;

  &:hover {
    color: var(--main-gold);
  }
`;

export const StyledFooterSocialInfoLink = styled.a`
  border-radius: 0.0625rem;
  cursor: pointer;
  height: 2.125rem;
  width: 2.125rem;
  border: 0.0625rem solid rgba(255, 255, 255, 0.3);
  margin-right: 1.125rem;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background: rgba(196, 177, 128, 0.1);
    border: 0.0625rem solid rgba(255, 255, 255, 0.8);
  }

  &:active {
    background: rgba(196, 177, 128, 0.3);
  }
`;

export const StyledFooterSocialInfoLinkIcon = styled(ReactSVG)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.125rem;
  width: fit-content;
  margin: 0.5rem auto;

  & svg {
    height: 1rem;
    width: 1.25rem;
  }
`;
